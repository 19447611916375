<template>
  <div class="container-right">
    <div class="pageNoticeindex" v-if="tabIdx=='tab1'">
      <div class="container-right-name">
          通知详情
          <div class="line"></div>
      </div>
      <div class="noticelist">

        <div class="noticeli" v-for="(notice,index) in list" :key="index">
          <div class="h3"> 
            {{notice.messageTitle}}
            <span class="time">{{notice.createTimeText}}</span>
          </div>
          <div class="p">{{notice.messageContent.content}}</div>
          <div class="enter" @click="seeDetail(index)">
            <span class="icon iconfont">&#xe656;</span>
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pagination.total"
          :current-page.sync="pagination.page"
          :page-size.sync="pagination.pageSize"
          @size-change="sizeChange"
          @current-change="currentChange"
          :pagerCount="5" 
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="tabIdx=='tab2'">
      <notice-detail
      :msg="msgDetail"
      @set-tab-idx="setTabIdx"></notice-detail>
    </div>
  </div>
</template>

<script>
import { queryNoticeMsg } from "@/api/NoticeService";
import NoticeDetail from './components/NoticeDetail.vue'
export default {
  name: 'Notice',
  components: { NoticeDetail },
  data () {
    return {
      tabIdx: 'tab1',
      list:[],
      msgDetail:null,
      pagination: {
        total:0,
        page: 1,
        pageSize: 10
      },
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      let that=this;
      queryNoticeMsg(this.pagination.page,this.pagination.pageSize).then(({data})=>{
          that.list=data.list;
          for(let i in data.list){
            data.list[i].createTimeText = this.dayjs(data.list[i].createTime*1000).format('YYYY-MM-DD HH:mm:ss');
          }
          this.pagination.total=data.total*1;
      });
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.init()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.init()
    },

      setTabIdx(tabIdx){
          this.tabIdx=tabIdx
      },
      seeDetail(index){
          this.msgDetail=this.list[index];
          this.setTabIdx('tab2')
      },
  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';

.noticelist{
  padding-bottom: 32px;
  .noticeli{
    position: relative;
    padding: 16px 80px 16px 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 109, 117, 0.06);
    border-radius: 2px;
    margin-bottom: 16px;
    .h3{
      position: relative;
      padding: 0px 132px 0px 0px;
      color: rgba(0, 18, 22, 0.85);
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
      .time{
        right: 0px;
        top: 2px;
        position: absolute;
        color: rgba(0, 18, 22, 0.35);
        font-size: 14px;
      }
    }
    .p{
      color: rgba(0, 18, 22, 0.85);
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .enter{
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 16px;
      opacity: 0.2;
      height: 56px;
      width: 56px;
      text-align: center;
      line-height: 56px;
      border-left: 1px solid rgba(0, 18, 22, 0.20);
      }
  }
}

</style>
