import request from '@/utils/request'



export function queryNoticeMsg(pageNumber,pageSize) {
    return request(
        'get',
        '/v1',
       `/cms/custom/notice/message/p/${pageNumber}-${pageSize}`
    );
}
